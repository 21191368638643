.login-main {
    width: 100vw;
    height: 100vh;
    background-color: white;
    color: white;
    display: flex;
    font-family: 'Inter', 'Franklin Gothic Medium';
}

.ekstra-space {
    padding-bottom: 20px;
}

.login-left {
    width: 100vw;
    height: 100vh;
    flex: 3;
    background-image: url("./img/login-background.jpg");
    background-color: #474aee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-logo-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 5%;
}

.login-logo {
    width: 250px;
}

.login-logo-text {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: whitesmoke;
}

.login-right {
    width: 100vw;
    height: 100vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-main {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    background-color: white;
}

.dashboard-card-stack {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}


.Tips-main {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    background-color: red;
}

.sidebar-main {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

}

.sidebar-content {
    position: fixed;
    height: 100vh;
    background-color: white;
}

.dashboard-main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 6;
    padding-left: 20px;
    padding-top: 20px;
    width: 100%;
    height: 100vh;
    gap: 20px;
    min-height: max-content;
    background-color: #F5F5F5;

}

.Tips-main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 6;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    min-height: max-content;
    background-color: #F5F5F5;
}

.sidebar-menu {
    z-index: 100;
    display: flex;
}

.sidebar-logo {
    cursor: pointer;
    width: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 11%;
}

.onetips-main {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    background-color: white;
}

.onetips-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 6;
    padding-left: 20px;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
}

.onetips-main-content {
    min-height: 100vh;
}

.onetips-stack-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.onetips-stack {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.onetips-tip-img {
    width: 400px;
    height: 350px;
    text-align: center;
    color: #474aee;
    font-family: 'Inter', 'Franklin Gothic Medium';
    object-fit: cover;
    background: rgb(221, 221, 221);
    border-radius: 5px;
}

.onetips-tip-video {
    width: 400px;
    height: auto;
    object-fit: contain; 
}

.onetips-info-container {
    width: 400px;
}

.tip-stream-main {
    width: 100vw;
    height: 100vh;
    background-color: #474aee;
}

.tip-stream-content {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tip-stream-yt {
    width: 66vw;
    height: 80vh;
}

.tip-stream-footer {
    position: fixed;
    width: 100vw;
    height: 15%;
    left: 0px;
    bottom: 0px;
    background-color: white;
}

.tip-stream-footer-content {
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.tip-stream-logo {
    width: 200px;
}


.mobile-sidebar-main {
    z-index: 100;
    position: fixed;
    background-color: white;
    left: 0;
    top: 0px;
    bottom: 0;
    width: 60px;
}

.mobile-sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.mobile-sidebar-logo {
    display: flex;
    cursor: pointer;
    width: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 11%;
}

.cloudshift-dashboard-main {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    background-color: white;
}

.cloudshift-dashboard-main-content {
    display: flex;
    flex: 6;
    padding-left: 50px;
    padding-top: 50px;
    height: 100vh;
    min-height: max-content;
    background-color: #F5F5F5;
}

.fileInput {
    display: none;
}

.settings-main {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    background-color: white;
}

.settings-main-content {
    display: flex;
    flex: 6;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    padding-left: 40px;
    padding-top: 40px;
    width: 100%;
    height: 100vh;
    min-height: max-content;
    background-color: #F5F5F5;

}

.news-roll-outer-container {
    display: flex;
    gap: 20px
}

.news-roll-container {
    background-color: white;
    width: 40vw;
    height: 50vh;
    border-radius: 10px;
}

.news-roll-text-container {
    padding: 25px;
}

.news-roll-weather-container {
    width: 15vw;
    height: 50vh;
    background-color: white;
    border-radius: 10px;
}

.news-roll-weather-inner-container {
    padding: 20px;
}

.admin-tip-stream-main {
    display: flex;
}


.admin-tip-stream-main-content {
    flex: 5;
    background-color: #474aee;
    min-height: 100vh;
    max-height: max-content;
}

.admin-tip-stream-inner-content {
    display: flex;
    gap: 20px;
    padding: 25px;

}

.card-test-container {
    width: 500px;
    height: 500px;
    background-color: white;
}

.add-button-style {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: #474aee solid 3px;
    border-radius: 5px;
    font-size: 80px;
    font-weight: bold;
    color: #474aee;
}

.resetpassword-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .login-main {
        width: 100vw;
        height: 100vh;
        background-color: white;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', 'Franklin Gothic Medium';
    }

    .login-left {
        width: 100vw;
        height: 100vh;
        position: absolute;
        background-image: url("./img/login-background.jpg");
        background-color: #474aee;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .login-logo-container {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin-top: 5%;
        margin-left: 5%;
    }

    .login-logo {
        width: 250px;
    }

    .login-logo-text {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        color: whitesmoke;
    }

    .login-right {
        z-index: 2000;
        max-width: 300px;
        height: 280px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .cloudshift-dashboard-main-content {
        display: flex;
        padding: 20px 0px 0px 70px;
        height: 100vh;
        min-height: max-content;
        background-color: #F5F5F5;
    }



    .settings-main-content {
        display: flex;
        flex: 6;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        padding-left: 80px;
        padding-top: 20px;
        width: 100%;
        height: 100vh;
        min-height: max-content;
        background-color: #F5F5F5;

    }

    .dashboard-main-content {
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        margin-left: 40px;
        padding-bottom: 20px;
    }

    .dashboard-card-stack {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }





    .onetips-main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding-left: 70px;
        padding-top: 20px;
        width: 100%;
        height: 100%;
        background-color: #F5F5F5;
    }

    .onetips-main-content {
        min-height: 100vh;
    }

    .onetips-stack-row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .onetips-stack {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .onetips-tip-img {
        width: 300px;
        height: 350px;
        object-fit: cover;
    }

    .onetips-info-container {
        width: 300px;
    }

    .Tips-main-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 6;
        padding-left: 70px;
        padding-top: 20px;
        height: 10%;
        min-height: max-content;
        background-color: #F5F5F5;
    }

    .all-tips-card {
        min-width: 290px;
        max-width: 340px;
    }




}